/* You can add global styles to this file, and also import other style files */

@import '~@angular/material/theming';
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@font-face {
    font-family: avenir-black;
    src: url(assets/fonts/avenir_ff/AvenirLTStd-Black.otf);
}

@font-face {
    font-family: avenir-roman;
    src: url(assets/fonts/avenir_ff/AvenirLTStd-Roman.otf);
}

@font-face {
    font-family: avenir-book;
    src: url(assets/fonts/avenir_ff/AvenirLTStd-Book.otf);
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-size: 16px;
    font-family: avenir-roman, Roboto, "Helvetica Neue", sans-serif;
}

.primary-bg-color {
    background-color: #FFD36A;
}

.subtitle-text-color {
    color: #000000;
}

.title-text-color {
    color: #155264;
}

.input-bg-color {
    background-color: #EBBB47;
}

.input-text-color {
    color: #767676;
}

.color-white {
    color: #FFFFFF !important;
}

.subtitle-text {
    font-family: avenir-roman, Roboto, "Helvetica Neue", sans-serif;
}

.sub-text {
    font-family: avenir-book, Roboto, "Helvetica Neue", sans-serif;
}

.title-text {
    font-family: avenir-black, Roboto, "Helvetica Neue", sans-serif;
}

.card-1 {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.boarder-all-15 {
    border-radius: 15px;
}

$mat-233bite-primary: ( 50: #e3eaec, 100: #b9cbd1, 200: #8aa9b2, 300: #5b8693, 400: #386c7b, 500: #155264, 600: #124b5c, 700: #0f4152, 800: #0c3848, 900: #062836, A100: #6fcdff, A200: #3cbcff, A400: #09aaff, A700: #009cee, contrast: ( 50: #000000, 100: #000000, 200: #000000, 300: #ffffff, 400: #ffffff, 500: #ffffff, 600: #ffffff, 700: #ffffff, 800: #ffffff, 900: #ffffff, A100: #000000, A200: #000000, A400: #000000, A700: #ffffff, ));
$mat-233bite-accent: ( 50: #fffaed, 100: #fff4d1, 200: #ffecb3, 300: #ffe494, 400: #ffdf7d, 500: #ffd966, 600: #ffd55e, 700: #ffcf53, 800: #ffca49, 900: #ffc038, A100: #ffffff, A200: #ffffff, A400: #fff5e1, A700: #ffedc8, contrast: ( 50: #000000, 100: #000000, 200: #000000, 300: #000000, 400: #000000, 500: #000000, 600: #000000, 700: #000000, 800: #000000, 900: #000000, A100: #000000, A200: #000000, A400: #000000, A700: #000000, ));
$mat-233bite-warm:(50: #f6e8e8, 100: #e9c5c5, 200: #da9f9f, 300: #cb7979, 400: #c05c5c, 500: #b53f3f, 600: #ae3939, 700: #a53131, 800: #9d2929, 900: #8d1b1b, A100: #ffc6c6, A200: #ff9393, A400: #ff6060, A700: #ff4747, contrast: ( 50: #000000, 100: #000000, 200: #000000, 300: #000000, 400: #ffffff, 500: #ffffff, 600: #ffffff, 700: #ffffff, 800: #ffffff, 900: #ffffff, A100: #000000, A200: #000000, A400: #000000, A700: #ffffff, ));
$primary: mat-palette($mat-233bite-primary);
$warm: mat-palette($mat-233bite-warm);
$accent: mat-palette($mat-233bite-accent);
$theme: mat-light-theme($primary, $accent, $warm);
@include angular-material-theme($theme);
$primaryPalette: mat-palette($mat-233bite-primary);
.mat-option.mat-selected:not(.mat-option-multiple) {
    background: mat-color($primaryPalette, 500);
}